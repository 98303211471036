import { ref, computed } from "vue";
import { getData, getItem, saveData} from '@/plugins/OdooService';
import { state, organisation, accountList, user as loginUser} from '@/plugins/authService';
import { selectedUser} from '@/plugins/adminService';
import { cart, cartAmount, cartVat, emergency, orderitems, preCartItemCount, preCartAmount, preCartVat, selectedCatalog, setCatalog } from "@/plugins/shopService";
import {moment} from "@/plugins/interfaceControler";
import { assortList, listAssort} from '@/plugins/siteService';
import { cancelOrder,currentOrder} from '@/plugins/orderService';

import {initOrders} from "@/plugins/siteService";

import config from '@/config';

export const procurationList:any = ref([])
export const todoList:any = ref([])
export const statList:any =ref([])
export const todoItem:any = ref()

const user = computed(()=>selectedUser.value || loginUser.value)

export function trasformSQLList(list:any){
 return list
  const ret = list.map((item:any)=>{
    const d:any = {
      id: item.x_id,
      active: item.x_active,
      hospital_id: item.x_hospital_id,
      name: item.x_name,
      customer_analytic_account_id: [item.x_customer_analytic_account_id,item.x_customer_analytic_account],
      user_id: [item.x_user_id, item.x_name],
      replace_user_id: [item.x_replace_user_id, item.x_replace_user],
      user_role: item.x_user_role,
      procuration: item.x_user_role == 'Budgethouder' && item.x_procuration==0 ? 100000 : item.x_procuration
    }
    return d
  })
  return ret
}

export async function initProcuration(account:any, roles = config.roles.filter((role:any)=>role!='Besteller')){
  procurationList.value = []  
  listAccountProcuration(account,true,roles)
 }
export async function listAccountProcuration(account:any, active:Boolean=true,roles = config.roles.filter((role:any)=>role!='Besteller'), usr=0){
  console.log('roles',roles)
  if (!account) return []
  const ret =  await getData('procuration/list',{account, hospital: organisation.value.id, active, roles, user: usr > 0 ? usr : null})
  procurationList.value = trasformSQLList(ret)
  return procurationList.value
}

export async function listTodos(top=10){
  todoList.value = await getData('procuration/todo',{approval: true, contact:user.value.id, top,sort: 'x_display_name Desc' })
}

export async function todoStats(group='x_display_name'){
  statList.value = await getData('procuration/todo',{approval: true, contact:user.value.id, group, })
  return statList.value
}
export const hasProcuration = computed(()=>procurationList.value.filter((p:any)=>p.customer_analytic_account_id && p.user_role == 'Budgethouder').length>0)
export const needProcuration = computed(()=>(cartAmount.value + cartVat.value) > maxAmount.value || procuration.value.length>0 || emergency.value || selectedCatalog.value.procuration)
export const needAmountProcuration = computed(()=>(cartAmount.value + cartVat.value) > maxAmount.value || procuration.value.filter((p:any)=>!p.role).length>0)
export const clearedProcuration = computed(()=>clearedExperts.value && (!needAmountProcuration.value || procuration.value.filter((p:any)=>p.procuration > cartAmount.value).length>0))
export const maxAmount = computed(()=>{
  if (selectedCatalog.value.procuration) return 0
  return user.value.procuration
})

export const isService = computed(()=>cart.value.filter((c:any)=>c.x_type == 'service').length>0)

export const preProcuration = computed(()=>{
  let list:any = []
  const aaList = orderitems.value.map((c:any)=> c.assort)
  const assort = assortList.value.filter((a:any)=>aaList.includes(a.id)).map((a:any)=>a.display_name)
  //console.log(assort)
  let amount = preCartAmount.value + preCartVat.value//*1.21 HACK BTW
  //if (user.value.procuration >= amount) return list
  
  if (maxAmount.value < amount) procurationList.value.sort(compare).map((p:any)=>{
    if (assort.includes(p.user_role) && !emergency.value) {                                        //MEDISCH Expertlid
      p.amount = 2
      p.order = 2
      p.title = 'Beoordeling Expertlid'
      p.role = "Expertlid " + p.name
      //list.push(p)
    }
    //Amount
    if (!assort.includes(p.user_role) && !p.name && p.hospital_id && list.filter((l:any)=>l.procuration >= amount).length==0) {
      p.amount = p.procuration
      p.order = 4
      p.title = 'Goedkeuring verlenen'
     // if (p.user_role=='Expertlid') p.amount = amount
      if (user.value._id !== p.user_id[0] && p.amount > 0) list.push(p)
    }
  })
  //if (list.length>1)list = list.filter((l:any)=>l.user_role!='Expertlid') //Only if not match
  //|| (emergency.value && p.user_role.toLowerCase().includes('spoed'))
  
  procurationList.value.filter((p:any)=>(p.user_role==selectedCatalog.value.name)).map((p:any)=>{
   // console.log(p)
      p.role = p.user_role
      p.order = 3
      p.title = p.name == 'Besteller' ? 'Gereedmelding' : 'Beoordeling Bestelling'
      list = addItemToList(list, p)
    })
  
  
  if (emergency.value) { //Spoed
    procurationList.value.filter((p:any)=>p.user_role.toLowerCase().includes('spoed')).map((p:any)=>{
      p.order = 1
      p.title = 'Beoordeling Spoedorder'
      list = addItemToList(list, p)
    })
  }
  //console.log(list.map((l:any)=>l.title))
  return list
})
export const gateKeepers = computed(()=>procurationList.value.filter((proc:any)=>proc.user_role == 'Gatekeeper').map((p:any)=>p.name).join(', '))
export const expertList = computed(()=>{
  if (!selectedCatalog.value.expert) return []
  const aaList = cart.value.filter((c:any)=>c.x_type=="vta" || (c.vta_type && c.vta_type.includes('vta'))).map((c:any)=>c.item ? c.item.assort : (c.assortment_id ? c.assortment_id[0] : (c.x_assortment || 'null')))
  return  assortList.value.filter((a:any)=>aaList.includes(a.id)).map((a:any)=>a.display_name) // && a.display_name=='Medisch'
})
export const hasVTA = computed(()=>cart.value.filter((c:any)=>c.vta_type).length>0)
export const clearedExperts = computed(()=>{
  if (emergency.value) return true
  if (!selectedCatalog.value.expert) return true
  return  expertList.value.length <=  procuration.value.filter((a:any)=>a.expert).length
})
export const procuration = computed(()=>{
  
  
  let amount = cartAmount.value + cartVat.value
  
  return getProcurationByAmount(amount)
  
})

export function getProcurationByAmount(amount:number, max = maxAmount.value, usr=user.value){
  let list:any = []
  let cleared = max > amount ? max :amount
  console.log(amount, max, cleared)
  procurationList.value.filter((p:any)=>p.user_id && (usr._id !== p.user_id[0] || !p.customer_analytic_account_id)).sort(compare).map((p:any)=>{
    
    if (expertList.value.includes(p.name) && !emergency.value && selectedCatalog.value.expert) {        
      p.amount = 3
      p.role = "Expertlid " + p.name
      p.expert = true
      p.order = 2
      p.title = 'Beoordeling Expertlid'
      list.push(p)
    } else if (emergency.value && p.user_role.toLowerCase().includes('spoed')){ //SPOeD
      p.role = 'Spoed'
      p.amount =1
      p.order = 1
      p.title = 'Beoordeling Spoedorder'
      list.push(p)
    } else if (p.procuration > maxAmount.value 
       //&& !p.name
       && (amount >= cleared || p.procuration == cleared)
      && config.approval.includes(p.user_role)){ //Procuratie amount
      p.amount = p.procuration
      delete p.role
      p.order = 4
      p.create = true
      p.title = 'Goedkeuring verlenen'
      list.push({...p})
      cleared = p.amount//if (p.amount > amount)
      console.log('cleared1',cleared)
    }else if (isService.value && p.user_role == config.portal.role){ //DIenst
      p.amount = p.name == 'Besteller' ? 5 : 2
      p.show_role = true
      p.order = p.name == 'Besteller' ? 5 : 3
      p.create = p.name == 'Besteller'
      p.title =  p.name == 'Besteller' ? 'Gereedmelding' : 'Beoordeling Bestelling' 
      p.role = p.name == 'Besteller' ? config.portal.title : config.portal.role
      list.push(p)
    } else if (!emergency.value){
      p.amount = p.name == 'Besteller' ? 5 : 2
      p.role = p.user_role
      p.order = p.name == 'Besteller' ? 5 : 3
      p.create = p.name == 'Besteller'
      p.title =  p.name == 'Besteller' ? 'Gereedmelding' : 'Beoordeling Bestelling' 
      if (selectedCatalog.value.name==p.user_role) list.push(p)
    }
  })
  console.log(list.sort(compareAmount))
  return list.sort(compareAmount)

}

export function checkProcurationAmount(amount:number){
  return procurationList.value.filter((p:any)=>p.procuration >= amount).length >0 
}
/*export const procuration11 = computed(()=>{
  let list:any = []
  const mac = cart.value.filter((c:any)=>c.item && c.item.assort && config.assort.filter((a:any)=>a.id==c.item.assort && a.name == 'Medisch').length>0).length
  if (mac>0){ //HACK: Generiek maken
    procurationList.value.filter((p:any)=>p.user_role == 'Expertlid').map((p:any)=>{
      p.role = 'Expertlid'
      if (list.filter((l:any)=>l.user_id[0]==p.user_id[0]).length==0) list.push(p)
    })
  }
  let amount = cartAmount.value + cartVat.value//*1.21 HACK BTW
  //if (user.value.procuration >= amount) return list
  if (maxAmount.value < amount) procurationList.value.sort(compare).map((p:any)=>{
    //Amount
    if (p.user_role !== 'Expertlid' && !p.x_name && p.hospital_id && list.filter((l:any)=>l.procuration >= amount).length==0) {
      p.amount = p.procuration
     // if (p.user_role=='Expertlid') p.amount = amount
      if (user.value._id !== p.user_id[0] && p.amount > 0) list.push(p)
    }
  })
  //if (list.length>1)list = list.filter((l:any)=>l.user_role!='Expertlid') //Only if not match
  //|| (emergency.value && p.user_role.toLowerCase().includes('spoed'))
  cart.value.map((c:any)=>{
    procurationList.value.filter((p:any)=>(c.catalog && c.catalog.name ==p.user_role && p.user_role !== 'Expertlid')).map((p:any)=>{
      p.role = p.user_role
      list = addItemToList(list, p)
    })
  })
  
  if (emergency.value) { //Spoed
    procurationList.value.filter((p:any)=>p.user_role.toLowerCase().includes('spoed')).map((p:any)=>{
      list = addItemToList(list, p)
    })
  }
  
  return list
})*/
function addItemToList(list:any,p:any){
  const Contact = list.filter((l:any)=>l.user_id[0] == p.user_id[0])[0]
  if (!Contact) {
    if (user.value._id !== p.user_id[0]) list.push(p)
  } else {
    if (!Contact.user_role.includes(p.user_role)) Contact.user_role += ', ' + p.user_role
  }
  return list
}

export async function addActivity(index:number, Summary:any=false, user_id=user.value._id, model = config.activity.model ){
  if (user_id==config.portal.id) {
    user_id = user.value._id
    Summary = config.portal.title
  }

  
  const type = config.activity.type.filter((t:any) => t.name == Summary.split(':')[0])[0].id
  
  const res = await saveData('order/activity',{
      "res_model_id": model, //HACK
      "res_id": index,
      "recommended_activity_type_id": false,
      "activity_type_id": type,
      "summary": Summary,
      "date_deadline": moment().format("YYYY-MM-DD"), //.add(1, 'minutes')
      "user_id": user_id, //user._id //HACK
      create_uid: user.value.id,
      write_uid: user.value.id,
      "note": false,
      "calendar_event_id": false,
      request_partner_id: user.value.id
  })
  //initOrders()
  return res
}
//activity_type_id note
let sentitems:any = {} //HACK multiple chats
export async function decline(id:number,note:String,order_id?:any){ //HACK 15
  const key = 'cancel' + id + (order_id || 0)
  if (sentitems[key]) return false
  sentitems[key] = true
  const res = await saveData('order/activity',{
    id ,note,
    activity_type_id: config.activity.type.filter((t:any) => t.name == "Afgekeurd")[0].id,
    "summary": "Afgekeurd",
    "date_deadline": moment().add('days',-2).format("YYYY-MM-DD") ,
    write_uid: user.value.id,
    request_partner_id: user.value.id
    //user_id
  })
  await getData('procuration/approve/' + id,null,false)
  if (currentOrder.value) currentOrder.value.state = 'cancel'
  if (order_id) await cancelOrder(order_id) //Cancel order
  //document.location.reload()
  //initOrders()
  return res
}
export async function unlink(id:number){ //HACK 15
  const res = await getData('order/activity/unlink/' + id)
  //initOrders()
  return res
}

export async function reApply(id:number,note:String,user_id = user.value.id){ //HACK 15
  const res = await saveData('order/activity',{
    id ,note,
    activity_type_id: config.activity.type.filter((t:any) => t.name == "Goedkeuring")[0].id,
    "summary": "Opnieuw Goedkeuring",
    "date_deadline": new Date().toISOString().split('T')[0] ,
    write_uid: user.value.id,
    request_partner_id: user.value.id
  })
  //initOrders()
  if (res && res.status=='OK') return true
  return false
}
export async function approve(index:any,note?:String){
  const ret = await getData('procuration/approve/' + index,null,false) //hack update message
  //initOrders()
  if (ret && ret.status=='OK') return true
  return false
}

function compare( a:any, b:any ) {
  if (a.procuration == b.procuration) return 0
  return a.procuration < b.procuration ? -1 : 1
}
function compareAmount( a:any, b:any ) {
  if (a.order != b.order) return  a.order < b.order ? -1 : 1
  if (a.amount == b.amount) return 0
  return a.amount < b.amount ? -1 : 1
}

export async function postMessage(model:string, index:number,message:string, email=false){
  const res = await getData('chatter/post/' + model + '/' + index, {message, user: user.value._id, from: user.value.name, email})
}
 