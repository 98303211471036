import { ref, computed, watch, nextTick } from "vue";
import { getData, getItem,openFile, saveData, nameSearch, copyModel, setAction, addFile} from '@/plugins/OdooService';
import { state, user, hasTag ,hasRole, connection, organisation} from '@/plugins/authService';
import { initProcuration, procuration, addActivity, decline, approve, reApply, todoList} from '@/plugins/procurationService';
import {prompt, moment, openToast} from '@/plugins/interfaceControler';
import {selectedCabinet, updateStockTimestamp, updateCabinetTimestamp} from '@/plugins/cabinetControler';
import { cart, cartAmount ,account, location, orderType, addToCart, emergency,orderNote, orderData, selectedCatalog, resetCart,addProductToCartById, addProductToCartByName, files } from '@/plugins/shopService';
import {initOrders,getNextItem} from "@/plugins/siteService";
import {sendReminders, selectedUser} from "@/plugins/adminService";
import config from "@/config";

export const orderList:any = ref([])
export const orderLines:any = ref([])
export const deliveryLines:any = ref([])

export const currentOrder:any = ref()
export const cummulativeOrders:any = ref([])
export const refOrder:any = ref()

export const ordered:any = []

export async function loadCummulativeOrders(cabinet=selectedCabinet.value){
  cummulativeOrders.value = []
  return 
  if (cabinet.active_stock){ //&& isConsole.value
      const ret = await listOrders({state: 'draft', cabinet:cabinet.id })
      cummulativeOrders.value = ret.map((o:any)=>{
        return {
          id: o.id, account: o.customer_analytic_account_id[0], name: o.name, location: o.partner_shipping_id[0]
        }
      })
  }
}
export const orderVats = computed(()=>{
  let amounts:any = {}
  if (!currentOrder.value || !currentOrder.value.orderlines) return amounts
  currentOrder.value.orderlines.map((c:any)=>{
    console.log(c.x_taxes_id)
    const tid:number = c.taxes_id ? c.taxes_id[0] : c.x_taxes_id
    const tax = config.tax.filter((t:any)=>t.id==tid)[0] || {value: 21}
    if (!amounts[tax.value]) amounts[tax.value] = 0
    amounts[tax.value] += c.x_price_subtotal * ((tax.value)/100)
  })
  console.log(amounts)
  return amounts
})
const { createElement } = document
  const { URL: { createObjectURL, revokeObjectURL }, setTimeout } = window

  export async function getOrder(index:number){
    if (!index) return false
    deliveryLines.value=[]
    currentOrder.value = await getItem('order/item',index)  
    return currentOrder.value
  }

export async function loadOrder(index:number, details=true, dynamic=false){
  if (!index) return false
  deliveryLines.value=[]
  if (!currentOrder.value || currentOrder.value.id!=index) await getOrder(index)
    if (details) loadOrderDetails(index)
      currentOrder.value.orderlines = [{loading: true, name: 'Orderitems aan het laden'}]
    currentOrder.value.orderlines = await getOrderLines({order: index, detail: currentOrder.value.state == 'draft' || dynamic})
  //currentOrder.value.activities = listMessages
   
   
  return currentOrder.value
 }

 export async function loadOrderDetails(index:number, hide=false){
  if (!currentOrder.value) return false
  const thread = await listThread(index,{})
  currentOrder.value.todo = await listTodo(index)
  
  currentOrder.value.attachments = thread.attachments
  currentOrder.value.messages = await listMessages(index)
  if (currentOrder.value.activities) currentOrder.value.activities =currentOrder.value.activities.filter((m:any)=>m.message_type!='user_notification')
 
 }

 export async function listOrders(options:any = {state: 'open', user:user.value._id}){
  options.hospital = organisation.value.name
  //options.user = user.value
  //{user:user.value._id, parent: organisation.value.id}
  const ret = await getData('order/list',options)
  if (options.page>1) {
    orderList.value = orderList.value.concat(ret)
  } else {
    orderList.value =  ret
    
  }
  return orderList.value
}

export async function getOrderLines(options:any = {}){
  //orderLines.value = await getData('order/lines',{partner:user.value.id, q})
  options.hospital = organisation.value.id
  const ret:any = await getData(`order/${(options.detail ? '' : 'backorder')}lines`,options)
  return ret
}

export async function listOrderLines(options:any = {}){
  //orderLines.value = await getData('order/lines',{partner:user.value.id, q})
  filter.value = options
  const ret:any = await getOrderLines(options)
  if (options.page>1) {
    orderLines.value = orderLines.value.concat(ret)
  } else {
    orderLines.value =  ret
    
  }
  
  return orderLines.value
}
export async function listDeliveryLines(options:any = {}){
  const ret:any = await getData('order/deliverylines',options)
    if (options.page>1) {
    deliveryLines.value = orderLines.value.concat(ret)
  } else {
    deliveryLines.value =  ret
    
  }
  
  return deliveryLines.value
}


export async function listActions(index:number,body={}){
  const ret = await getData('order/activity/' + index,body)
  return ret
}
export async function listTodo(order:number){
  const ret = await getData('procuration/todo',{order})
  return ret //.filter((t:any)=>t.x_button > 0)
}
export async function listMessages(index:number,body={}){
  const ret = await getData('chatter/messages/' + index,body)
  return ret
}

export async function listThread(index:number,body:any={}){
  body.model = "sale.order"
  //const ret = await getData('chatter/messages/' + index,body)
  const ret = await getData('chatter/thread/order/' + index,body,false)
  return ret
}
export function getVTAStatusID(code:Number){
  const status = config.vta_status.filter((c:any)=>c.code==code)[0]
  if (status) return status.id
  return 29
}
export function getCartData(options=orderData.value){
  const order_line:any = [] 
  let amount:number = 0
  let counter = 0
  if (!selectedUser.value) selectedUser.value = user.value
  //cart.value.filter((c:any)=>(!c.x_location_id || c.x_location_id==options.location) && !c.x_account_id || c.x_account_id==options.x_account_id).map(async (item:any)=>{
  options.items.map(async (item:any)=>{ 
    let vta_type = item.vta_type
    if (selectedCatalog.value.vta=== true && !vta_type) vta_type  = 'vta_onbekend'

  amount += item.x_list_price * item.quantity
    const name = item.display_name || item.name || item.description
    const ol:any = {
      "product_id": item.x_product_id,
      name,
      "customer_analytic_account_id": options.account,
      "product_group_id": item.x_product_group_id,
      //"supplier_id": item.x_supplier_id,
      
      "price_unit": item.x_list_price,
      "price_total": item.x_list_price * item.quantity,
      "product_uom_qty": item.quantity,
      cabinet_id: item.x_cabinet_id || options.cabinet_id,
      "zxl_contract_id": item.x_contract_id,
      "wh_shelf": item.x_shelf,
      "wh_bin": item.x_bin,
      "extra_note" : item.extra_note,
      "internal_note": item.x_shelf_location, //
      "type": vta_type,
      "vta_status_id": options.emergency ? getVTAStatusID(10) : (vta_type ? getVTAStatusID(1) : getVTAStatusID(0)), //SPOED  config.vta_status
      "product_assortment_id": item.assortment_id ? (item.assortment_id[0] || item.assortment_id) : (item.assort || item.x_assortment || 10),
      "prefered_supplier_id": item.x_supplier_id[0] ? item.x_supplier_id[0]: item.x_supplier_id,
      //"customer_investment_id": options.invest ? options.invest.id : null,
      //"x_studio_group" : item.x_studio_group,
      sequence: 10 + (item.sequence || counter),
      "discount": 0}
    order_line.push([0,0,ol ])
    counter++
  })
  if (order_line.length==0) return false
  options.type = selectedCatalog.value.id
  options.location = options.location || cart.value[0].x_location
  return  {
    id: options.order_id ,  //cummulativeOrders
    "partner_id": options.cabinet_id || organisation.value.id !==  selectedUser.value.parent_id[0] ?  options.location : selectedUser.value.id, 
    customer_analytic_account_id: options.account,
    partner_shipping_id: options.location,
    cabinet_id: options.cabinet_id,
    stream_id: selectedCatalog.value.id,
    "emergency_order": options.emergency,
    project_id: options.project ? options.project.id : null,
    zxl_contract_id: options.contract ? options.contract.id : null,
    "customer_investment_id": options.invest ? options.invest.id : null,
    "client_order_ref": options.name,
    //"x_studio_procuration": selectedUser.value.procuration,//HACK PROCURATION 
    order_line,
    notes: options.note,
    customer_user_id: selectedUser.value._id || user.value._id,
    user_id: selectedUser.value._id && selectedUser.value._id != user.value._id ? user.value._id : null
  }
}
export const orderQueUe:any = ref([])
export const orderCartList = computed(()=>{
  
  const obj:any = {}
  cart.value.sort(sequence).map((c:any)=>{
    const key = c.x_location + '_' + c.x_account_id
    if (!obj[key]) {
      let iitem = {...orderData.value}
      iitem.x_account_id = c.x_account_id
      if (c.x_account_id) iitem.account = c.x_account_id
      if (c.x_location) iitem.location = c.x_location_id
      iitem.location_name = c.x_hospital2
      iitem.cabinets =  [ ]
      iitem.items =  [ ]
      obj[key] = iitem
    }
    obj[key].items.push(c)
    if (c.x_cabinet_id) obj[key].cabinets.push({
        id: c.x_cabinet_id,
        name: c.x_cabinet,
        code: c.x_cabinet_code
      })
      const cOrder = cummulativeOrders.value.filter((o:any)=>o.account==c.x_account_id && o.location == c.x_location_id)[0]
      
      if (cOrder) {
        obj[key].order_id = cOrder.id
        obj[key].order_name = cOrder.name
      }
  })
  if (Object.keys(obj).length > 0) return Object.values(obj)
  
  orderData.value.items = cart.value
  return [orderData.value]
})
function sequence( a:any, b:any ) {
  if (a.sequence == b.sequence) return 0
  return a.sequence < b.sequence ? -1 : 1
}

export async function orderCart(options?:any,proc= false){
  orderData.value.ordered = true
  if (!options)  {
    options={...orderData.value}
    options.items = cart.value
  }
  /*console.log('contact',user.value.id)
  console.log('account',account.value)
  console.log('project',project.value)
  console.log('location',location.value)
  console.log('cart',cart.value)
  console.log('procuration',procuration.value)*/
  
  if (selectedCabinet.value && !options.cabinet_id) options.cabinet_id = selectedCabinet.value.id
  if (!options.items) options.items = cart.value
  const cartData:any = getCartData(options)
  
  if (!cartData) return false
  const orderHash:string = [cartData.customer_analytic_account_id, cartData.partner_shipping_id, cart.value.length, cartAmount.value].join('_')
  
    //return openToast("FOUT: Deze bestelling is al geplaatst, indien u door wilt gaan dan kunt u de pagina verversen",{color: 'danger',duration:5000})
  if (ordered.includes(orderHash))  return false
  ordered.push(orderHash)
  let response
  try {
    response = await saveData('order', cartData)
  }catch(e){
    return false
  }
  if (!response || !response.index) return false
  if (response.index.status && response.index.status =='ERROR') return false
  if (response.index && response.index.code == 200) return false
  options.ordered = true
  options.items.map(async (c:any)=>c.ordered = true) //Mark ordered
  
  let index = response.index === true ? cartData.id : response.index
  
  

 
    if (selectedCatalog.value.value == 'Kastenscan'){
      //cart.value = cart.value.filter((c:any)=>!c.ordered)  //DElete ordered ordered
    } else { //HACK PROCURATION 
      let ret = await Promise.all(
        procuration.value.filter((p:any)=>p.create).map(async (p:any)=>{
          const ret:any = await addActivity(index, p.title + ": " + p.user_role.split(' ')[0], p.user_id[0])
          return ret.index
        })
      )
       resetCart(true) 
    }
  //if (item.x_stock_id) updateStockTimestamp(item.x_stock_id) LAST SCANDATE samenvoegen
  if ( options.cabinet_id) {
    updateCabinetTimestamp( options.cabinet_id)
   /* if (response.index === true) { //existing order
      postMessage('sale.order',index, "Order aangevuld")
    }else{
      postMessage('sale.order',index, "Order aangemaakt")
    }*/
  } else{
    if (files.value && files.value.length> 0) await updoadFiles({id: index}, files.value)
    if (cartData.stream_id==1 && procuration.value.length==0){ //confirm
          await confirmOrder(index, selectedCatalog.value.id)
    }else if (procuration.value.filter((p:any)=>p.create).length>0) {
      setTimeout(()=>{
        sendReminders(index)
      },3000)
      
    }
  }
  try {
    return index
    //(ret.filter((r:number)=>r>0).length!==procuration.value.length)
  }catch(e){
    return false
  }
}
export function statusMessage(row:any){
  if (row.delivery_status == 'full') return 'Afgeleverd'
  if (row.invoice_count>0) return 'Afgehandeld'
  if (row.state=="cancel") return 'Geannuleerd'
  if (row.delivery_count>0 && row.delivery_status=='pending') return 'In bestelling'
  if (row.delivery_count>0) return 'In bestelling'
  if (row.delivery_count>0) return 'Verzonden'
  
  if (row.purchase_order_count>0) return 'Besteld'
  if (!row.activity_type_id && row.state == 'sale') return  'In bestelling'// 'Verwacht ' + moment(row.commitment_date || row.expected_date).format('L')
  if (row.state == 'sale') return 'Besteld'
 
  
  let stat = row.activity_type_id ? config.activity.type.filter((a:any)=>a.id==row.activity_type_id[0])[0].name : 'Aanvraag'
  if (!row.activity_type_id && row.state !== 'sale') return stat
  return stat //+ ' ' + row.activity_user_id[1]
}

export function itemStatusMessage(row:any){
    if (row.x_delivery_unconfirmed && !row.x_expected_delivery) return 'Leverdatum onbekend'
  const arr = ['<div>']
  if (row.x_qty_delivery!=row.x_quantity) arr.push(row.x_qty_delivery)
  arr.push(row.x_delivery_status + '</div>&nbsp;&nbsp;')
  if (row.x_qty_to_deliver == 0 ) {
    if (row.x_trip) arr.push('Rit ' + row.x_trip + ': ')
    
  } else if (row.x_delivery_status !='Verzonden' && row.x_expected_delivery) arr.push('Verw:')
  if (row.x_qty_received && row.x_qty_received!=row.x_quantity) arr.push(row.x_qty_received + "/" + row.x_quantity + '<br>')
  if (row.x_expected_delivery) arr.push(moment(row.x_expected_delivery).format('DD-MM-YYYY'))
  else if (row.x_delivery_status=='Backorder') arr.push('Datum onbevestigd')
  if (moment(row.x_initial_expected_date).format('DD-MM-YYYY')!=moment(row.x_expected_delivery).format('DD-MM-YYYY') && row.x_delivery_status=='Backorder') {
    arr.push('<br><span style="opacity:0.8;font-size:0.9em">&nbsp;&nbsp;&nbsp;&nbsp;Initieel: ' + moment(row.x_initial_expected_date).format('DD-MM-YYYY') + '</span>') 
  }
  if (row.lines) { //double delivery lines
    row.lines.map((r:any)=>arr.push(itemStatusMessage(r)))
  }
  
  return arr.join(' ')
}
export function itemStatusMessage2(row:any){
if (row.x_expected_unconfirmed) return 'Niet bevestigd'

if (row.x_order_state=="draft" || row.x_status == 'Afgekeurd') {
  if (row.x_article_type=='vta' &&  row.x_status == 'Geen') return 'Openstaande goedkeuringen'
  let status =row.x_status ||  'Aanvraag'
  config.vta_status.map((s:any)=>{
    if (s.value==row.x_status) status = s.text
  })
  return status
}
if (row.x_order_state=="cancel") return 'Geannuleerd'
if (row.x_qty_received) return row.x_qty_received + " ontvangen"// + moment(row.x_confirmed_date).format('DD-MM-YYYY')
if (row.x_qty_delivered==row.x_quantity) return 'Geleverd'
if (row.x_qty_delivered) return row.x_qty_delivered
if (!row.x_expected_delivery && row.x_initial_expected_date) row.x_expected_delivery = row.x_initial_expected_date
if (!row.x_expected_delivery && !row.x_delivery_status && row.x_purchase_order_line_id) return 'In bestelling'
if (!row.x_expected_delivery && !row.x_delivery_status) return 'In behandeling'

//if (!row.x_expected_date) return row.x_delivery_status
const arr = [row.x_delivery_status]
if (row.x_confirmed_date) {
  arr.push('bevestigd:')
  arr.push(moment(row.x_confirmed_date).format('DD-MM-YYYY'))
  //if (moment(row.x_confirmed_date).format('DD-MM-YYYY')==moment(row.x_expected_delivery).format('DD-MM-YYYY')) return arr.join(' ')
  if (row.x_expected_date) arr.push('<br><span style="opacity:0.8;font-size:0.9em">origineel:')
  //return 'Bev.: ' + moment(row.x_confirmed_date).format('DD-MM-YYYY') + moment(row.x_confirmed_date).format('DD-MM-YYYY')!=moment(row.x_expected_dat).format('DD-MM-YYYY') ? '<br><span style="opacity:0.8;font-size:0.9em">origineel ' + moment(row.x_expected_date).format('DD-MM-YYYY') + '</span>' : ''
} else if (row.x_expected_delivery) 
arr.push('verwacht: ')
if (row.x_expected_delivery) arr.push(moment(row.x_expected_delivery).format('DD-MM-YYYY'))
if (arr.length==0) return 'In behandeling'
return arr.join(' ')
}
export async function updoadFiles(order:any,files:any){
  
  //files.map(async (file:any)=>{
    await addFile(order.id,files)
    files.value = []
  //})
}
export async function downloadFile(index:number, name:string){
  
  const blob = await openFile('order/download/' +index)
  const url = createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.setAttribute('href', url)
  anchor.setAttribute('download', name)
  anchor.click()
  setTimeout(() => { revokeObjectURL(url) }, 100)
}
export async function downloadFiles(index:number){
  const file = await getItem('order/download',index)
  console.log(file)
  const { createElement } = document
  const { URL: { createObjectURL, revokeObjectURL }, setTimeout } = window

  const blob = new Blob([file.raw], { type: file.mimetype })
  console.log(file.mimetype)
  const url = createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.setAttribute('href', url)
  anchor.setAttribute('download', file.name)
  anchor.click()
  
  setTimeout(() => { revokeObjectURL(url) }, 100)

  return file
}

export async function confirmOrder(index:number, stream:number=1){
  try{ 
    getData('order/confirm/' + index + '/' + stream) //HACK Not working
  } catch(e) {
    return false
  }
  return true
  //await saveData('order', {id: index, state: "sale"})
  //if (currentOrder.value)  currentOrder.value.state = 'sale'
}
export async function cancelOrder(index:number){
  try{ 
    await getData('order/cancel/' + index) //HACK Not working
  } catch(e) {
    console.log(e)
    return false
  }
  return true
  //await saveData('order', {id: index, state: "sale"})
  //if (currentOrder.value)  currentOrder.value.state = 'sale'
}
export async function confirmMove(index:number){
  
  const ret = getData('stock/confirm/' + index,null,false) //HACK Not working
  return ret
  //await saveData('order', {id: index, state: "sale"})
  //if (currentOrder.value)  currentOrder.value.state = 'sale'
}

export async function approveOrder(index:number){
  const ret = await approve(index)
  await getNextItem(index)
  //await initOrders()
  todoList.value = todoList.value.filter((td:any)=>td.id!=index)
  if (currentOrder.value) {
    await loadOrder(currentOrder.value.id)
    await confirmOrder(currentOrder.value.id)
  }
  
}
export async function reOrder(index:any=null){
  if (!currentOrder.value || !currentOrder.value.orderlines) return false
  refOrder.value = currentOrder.value
  account.value = currentOrder.value.customer_analytic_account_id[0]
  location.value = currentOrder.value.partner_shipping_id[0]
  //emergency.value = currentOrder.value.emergency
  reOrderLines()
  //if (index) await approve(index)
  //archive('order',currentOrder.value.id)
}

export async function reOrderLines(){
  
  currentOrder.value.orderlines.map(async (product:any)=>{
    product.quantity = product.x_quantity
    delete product.x_quantity
    delete product.x_location
    delete product.x_account_id
    addToCart(product)
  })
}

export async function declineOrder(index:number){
  if (!currentOrder.value) return false
  prompt("Afkeuren", "Geef de reden van afkeuring op","", "Opmerking", async (response:any)=>{
        const ret = await decline(index, response.description)
        await getNextItem(index)
        setTimeout(async ()=> currentOrder.value.todo = await listActions(index,{}),1000)
  })
  
}

export async function reApplyOrder(index:number){
  if (!currentOrder.value) return false
  prompt("Opnieuw goedkeuren", "Geef de reden op","", "Opmerking", async (response:any)=>{
        const ret = await reApply(index, response.description, currentOrder.value.user_id[0])
        initOrders()
        await loadOrder(currentOrder.value.id)
  })
  
}

export async function saveOrderLine(options:any){
  await getData('order/line/save',options) 
}
export async function getPackFromPick(pack:number){
  if (!pack) return
  const ret = await getItem('order/package',pack) 
  return ret
}
export async function getStockMoveLines(parent:number,pack:number){
  if (!parent || parent==0) return []
  const ret = await getData('order/stocklines/' + parent + '/' + pack) 
  return ret
}

export async function setReceivedQty(item:any,quantity?:number,price?:number){
  item.qty_received = quantity || item.x_quantity
  let options:any = {
    id: item.x_purchase_order_line_id,
    line_id: item.x_id,
    qty_received : item.qty_received,
    x_qty_delivered : item.x_qty_delivered,
    order_id: item.x_order_id,
    porder_id: item.x_porder_id,
    porder: item.x_purchase_name,
    name: item.x_name
  }
  if (price) options.price_unit = price
  
  await getData('order/received', options)
  /* options = {
    id: item.x_id,
    qty_delivered : item.qty_received
  }*/
  //await saveOrderLine(options)
}
export async function setActiveReceivedQty(index:number,quantity:number){
  if (!index) return false
  return await getData('order/received/' + index + '/' + quantity)
}
export async function setDoneQty(stock_move_line_id:number,quantity:number){
    if (!stock_move_line_id) return false
    return await getData('order/done/' + stock_move_line_id + '/' + quantity)
}


export function setCartData(order:any){
  selectedCatalog.value.id = order.stream_id
  orderData.value.type = selectedCatalog.value.id
  orderData.value.account = order.customer_analytic_account_id
  orderData.value.location = order.partner_shipping_id
  orderData.value.cabinet_id = order.cabinet_id
  orderData.value.emergency = order.emergency_order
  if (order.project_id) orderData.value.project = {id:order.project_id}
  if (order.zxl_contract_id) orderData.value.contract = {id:order.zxl_contract_id}
  orderData.value.note = order.notes
  order.order_line.map(async (item:any)=>{
    await addProductToCartByName(item[2].name,item[2].product_uom_qty)
  })
  
}
const f = {tabs: 'orderlines', page: 1, top: 30, state: false, partner:user.value ? user.value.id: 0, group:false, sort:false, range: {lower: 0, upper: 100000 }, account: false, type:false}
export const filter:any = ref({...f})

export async function filterTable(key:string, value?:any){

  if (!key) {
    filter.value = {...f} //reset
  }else{
    filter.value[key] = value
  }
  listData()
}

export async function listData(){
  filter.value.page = 1
  currentOrder.value = null
  filter.value.partner = selectedUser.value ? selectedUser.value.id : null
  if (filter.value.cabinet) {
    delete filter.value.type
    delete filter.value.account
  }
  if (filter.value.tabs=='orderlines') return listOrderLines(filter.value)
  await listOrders(filter.value)
}

export async function postMessage(index:number,message:string, model='sale.order'){
  const res = await getData('chatter/post/' + model + '/' + index, {message, user: user.value._id, from: user.value.name})
}

 