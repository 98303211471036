import { ref, computed, watch } from "vue";
import { getData, getItem, saveData} from '@/plugins/OdooService';
import { state, user, hasTag ,hasRole, organisation, isAdmin, initUser, accountList} from '@/plugins/authService';
import { orderList, listOrders} from '@/plugins/orderService';
import { listTickets} from '@/plugins/helpdeskService';
import {selectedCabinet} from '@/plugins/cabinetControler';
import {listTodos,  todoList} from '@/plugins/procurationService';
import {setCatalog, orderTypes} from '@/plugins/shopService';
import config from '@/config'

export const posts:any = ref([])
export const articles:any = ref([]) 
export const assortList:any = ref([])
export const hospitalList:any = ref([])
export const nextTodoItem:any = ref()

export const hasReplaceMent:any = computed(()=>accountList.value.length < 100 && accountList.value.filter((u:any)=>u.replace_user_id).length>0)
export async function initSite(catalog?:string){
  listOrderTypes(catalog)
  listAssort()
  initUser()
  //posts.value = await getData('site/blog',{})
  //articles.value = await getData('site/article',{parent: 'Portal'})
  //initOrders()
  //listTickets()
  
  
  let ids:any = [organisation.value.id]
  if (user.value.hospital_id) ids.push(user.value.hospital_id[0])
  if (user.value.hospital_ids) ids= user.value.hospital_ids
  if (ids.length>0) {
    hospitalList.value = await getData('admin/hospital/list',{ids})
    //hospitalList.value.push(organisation.value)
  } else {
    hospitalList.value = []
  }
  //if (selectedCabinet.value) setCatalog('Kastenscan')
 }
 
export async function loadArticles(root:String,parent:String,iindex?:string ){
  const index:any = iindex ? parseInt(iindex) : null
  if (parent) return articles.value = await getData('site/article',{root,index})
  articles.value = await getData('site/article',{root,index})
}
export async function loadPosts(options:any){
  posts.value = await getData('site/blog',options)
}

 export async function initOrders(){
  listOrders({state: 'open', user:user.value._id, top:5})
  listTodos()
 }
 export async function listAssort(all=false){
  
  if (!all && assortList.value.length > 0)   return false
  const ret = await getData('admin/account/list/assortment',{assortment_ids: all ? null : organisation.value.assortment_ids})
  if (all) return ret.sort(compare)
  assortList.value =  ret.sort(compare)
}

 export async function listOrderTypes(catalog?:string){
  if (orderTypes.value.length > 0)   return setCatalog()
  const ret = await getData('catalog/type',{})
  ret.map((r:any) => {
    const item = config.catalog.filter((p:any)=>p.name==r.name)[0]
    //item.id = r.id
    r =Object.assign(r,item || {})
    return r 
  })
  orderTypes.value = ret
  setCatalog(catalog)
}
 export function getNextItem(index:any, remove=true){
  nextTodoItem.value = null
  if (todoList.value.length==0) return false
  todoList.value.map((item:any,jj:number)=>{
    if (item.id==index && todoList.value[jj+1] ) nextTodoItem.value = todoList.value[jj+1]
  })
  if (remove) todoList.value = todoList.value.filter((item:any)=>item.id!==index)
  return nextTodoItem.value
}
function compare( a:any, b:any ) {
  if ((a.name || a.x_name) == (b.name || b.x_name)) return 0
  return (a.name || a.x_name) < (b.name || b.x_name) ? -1 : 1
}