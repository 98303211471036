import { ref, computed, watch } from "vue";
import { getData, getItem, saveData} from '@/plugins/OdooService';
import {  connection, organisation, user} from '@/plugins/authService';
import {  selectedProduct, supplier, selectedCatalog} from '@/plugins/shopService';
import { assortList, listAssort} from '@/plugins/siteService';
import {moment} from '@/plugins/interfaceControler';
import {scanType} from "@/plugins/scanControler";
import config from '@/config'

export const LinkedProducts:any = ref([])
export const productPackaging:any = ref([])
export async function addProduct(item:any, tags?:any, type = 'vta', dummy?:string){ //vta,oci,service
  if (!dummy) dummy = type
  
  if (!dummy || dummy == 'undefined') dummy = selectedCatalog.value.dummy || 'vta'
  if (!dummy || dummy == 'undefined') dummy = 'vta'
  let product: any= {
        "name": item.name,
        "list_price": item.list_price || 0,
        "standard_price": item.list_price || 0,
        "default_code": item.barcode,
        "pcn": item.supplier_code,
        customer_product_group_id: item.group || 1376, //HACK
        assortment_id: item.assort,
        service_to_purchase: type == 'service', //selectedCatalog.value.service == true || type == 'service',
        //route_ids: config.routes.oci, //dropship
        product_image_url: item.image,
        //categ_id: 6
        }
    if (item.uom_id) {
      product.uom_id = item.uom_id
      product.uom_po_id = item.uom_id
    }
    product.unit_of_measure_description = 'Stuk'
    if (item.unit_of_measure_description) product.unit_of_measure_description = item.unit_of_measure_description
    if (item.uom) {
          product.uom_id = item.uom
          product.uom_po_id = item.uom
        }
    if (item.unit){
      if (item.unit.uom) {
        product.uom_id = item.unit.uom
        product.uom_po_id = item.unit.uom
        product.unit_of_measure_description = item.unit.db + (item.size > 0 ? ' (' + item.size + ' st)' :'')
      }
       
    }
    if (item.vat){
      const vat = config.vat.filter((v:any)=>v.value==item.vat)[0] || {id: 4}
      product.taxes_id =  [[6, false,[vat.id]]]      
    }
    
        //HACK sellerIDs
    const supplierID = item.seller_ids || (item.supplier && item.supplier.id) ? item.supplier.id : item.x_supplier_id
    if (supplierID) product.seller_ids = [
      [
        0,
        0,
        {
          sequence: 1,
          partner_id: supplierID,
          product_name: item.name,
          product_code: item.supplier_code,
          product_uom: product.uom_id,
          min_qty: 1,
          price: item.list_price || 0,
          currency_id: 1,
          delay: 2,
          company_id: 1,
        },
      ],
    ]
  const unitName = product.unit_of_measure_description
  //return console.log({...selectedCatalog.value})
   
   product = await getData('catalog/copy/product/dummy_' + dummy, product,false)      
   //product = res[0]
   //console.log(product)
   if (!product || !product.id) return false


   const supp = item.supplier || supplier.value || {}
   product.x_supplier_id = supplierID
   product.x_supplier = supp.name
   product.seller_ids = supplierID
   product.x_sales_unit = unitName
   
   await setCatalog(product)
   selectedProduct.value =setXproduct(product, dummy)
   selectedProduct.value.x_sales_unit = unitName
   return selectedProduct.value
}
export async function updateCatalog(cat:any){
  const res = await saveData('catalog/item', cat)
  return res
}
export async function setCatalog(product:any){
  const data = {
              id: product.id,
              catalog_ids: [
              [
                0,
                0,
                {
                    "hospital_id": organisation.value.id,
                    "product_id": product.id,
                    "start_date": moment().format("YYYY-MM-DD"),
                    "currency_id": 1,
                    "price": product.list_price,
                    "tag_ids": [
                        [
                            6,
                            false,
                            []
                        ]
                    ],
                    "phase": "new",
                    "active": true,
                    "company_id": 1,
                    "vendor_id": product.x_supplier_id,
                    "vendor_product_name": product.name,
                    "vendor_product_code": product.default_code,
                    "quantity": 1,
                    "delivery_lead_time": 2,
                }
            ]
          ]
        }
    await saveProduct(data)
}

//x_sales_unit: config.unit.filter((u:any)=>u.name == item.unit ? item.unit.name : 'Stuk')[0].display(item.size,item.quantity || 1),
export function setXproduct(item:any, type?:String){
  type = selectedCatalog.value.value
  item.x_default_code= item.default_code
  item.x_barcode = item.barcode
  item.display_name=  item.name
  item.quantity = item.quantity
  item.x_groduct_group_id = item.group
  item._oci = true
  item.x_sale_ok = true
  item.categ_id = type ? [0,type.toUpperCase()] : null
  item.x_assortment= item.assortment_id ? item.assortment_id[0] ||item.assortment_id : 1
  item.x_type = item.x_type || type
  item.x_sales_unit = item.x_sales_unit || item.unit_of_measure_description || "Stuks"
  item.x_supplier =  item.x_supplier || item.source
  item.x_supplier_id = item.x_supplier_id
  item.x_product_id = item.id
  item.x_image = item.product_image_url
  item.x_list_price = item.x_list_price || item.list_price
  
  item.uom = item.uom
  if (selectedCatalog.value.banner && !item.x_ribbon) {
    item.x_ribbon =selectedCatalog.value.banner + ';green' 
  }
  
  return item
}

export async function addProductOld(item:any, tags?:any, type = 'product'){ //consu,product,service
  let product: any = {
        id: item.id,
        detailed_type: type,
        service_to_purchase: type =='service',
        default_code: item.barcode,
        name: item.name,
        list_price: item.list_price || item.lst_price, 
        standard_price: item.list_price || item.lst_price, 
        description: item.description,
        customer_product_group_id: item.group || 1376, //HACK
        assortment_id: item.assort,
        route_ids: config.routes.oci, //dropship
        product_image_url: item.image,
        //base_unit_name: item.unit,
        //product_tag_ids: tags ? [[6,false, tags]]: null,
        seller_ids: [
          [
            0,
            0,
            {
              sequence: 1,
              partner_id: item.seller_ids || item.supplier.id || item.x_supplier_id,
              product_id: false,
              product_name: false,
              product_code: false,
              date_start: false,
              date_end: false,
              min_qty: 0,
              price: item.list_price,
              currency_id: 1,
              delay: 1,
              company_id: 1,
            },
          ],
        ],
      };
      const res = await saveData('catalog/product', product)
      product = res[0]
      if (!product) return false

     /* saveProduct({id:product.id,
        catalog_ids: [
          [
              0,
              "virtual_3",
              {
                  "hospital_id": organisation.value.id,
                  "product_id": product.id,
                  "start_date": moment().format('YYYY-MM-DD'),
                  "user_id": user.value._id,
                  "currency_id": 1,
                  "price": item.list_price,
                  "phase": "new",
                  "active": true,
                  "company_id": 1,
                  "vendor_id": item.seller_ids || item.supplier.id,
                  "vendor_product_name": "GEBAKSVORKJE MODEL RESISTANCE  BOX a 12",
                  "vendor_product_code": "123456",
                  "quantity": 1
              }
          ]
      ],})*/
    //product = await getItem('catalog/item',product.id,{hospital: organisation.value.id})
    const supp = item.supplier || supplier.value || {}
    selectedProduct.value ={id:product.id,
        x_product_id: product.id,
        x_sale_ok: true,
        x_default_code: product.default_code,
        x_barcode: product.barcode,
        display_name: item.name,
        item,
        quantity: item.quantity,
        x_groduct_group_id: item.group,
        x_supplier: supp ? supp.name || supp : 'Onbekend',
        x_sales_unit: config.unit.filter((u:any)=>u.name == item.unit ? item.unit.name : 'Stuk')[0].display(item.size,item.quantity || 1),
        x_list_price: item.list_price
    }
    return selectedProduct.value
 }

 export async function saveProduct(product:any){
  const res = await saveData('catalog/product', product)
  return res
}
export async function updateProduct(product: any){ //{q:string, tags:any=null}cabinet_location
  return await saveData('admin/product', product)
}
export async function getLinkedProducts(product=selectedProduct.value){
  if (!product || !product.x_template_id) return false
  LinkedProducts.value = []
  if (product.x_linked_product_ids>0) LinkedProducts.value = await getData('catalog/linked/' + product.x_template_id + '?hospital=' + organisation.value.id)
  productPackaging.value = await getData('catalog/packaging/' + product.x_product_id)
  if (selectedProduct.value && selectedProduct.value.x_sales_unit) selectedProduct.value.count = parseInt(selectedProduct.value.x_sales_unit.toUpperCase().replace('VERP','') || '1')
  LinkedProducts.value.map((p:any)=>p.quantity = parseInt(p.x_sales_unit.toUpperCase().replace('VERP','')))
}

export function qty2Order(product:any){
  if (!product.x_active_stock || !product.x_sale_ok) return 0
  
  const oCount = getOrderCount(product)
  let x_count =  product.x_count - (product.quantity||0) + oCount
  //console.log(product.x_name,'ref count',product.x_count, (product.quantity||0), oCount, x_count)
  if (x_count<=0) x_count = 0
  
  //if ((x_count + oCount) >= product.x_max) return 0 //inclusief voorraad
  let quantity = 0 
  if (x_count  <= product.x_min) quantity = product.x_max - x_count // first 
  //console.log('quantity', quantity, product.x_sale_quantity)
  /*if (x_count <= product.x_min && product.x_count > product.x_min) quantity = product.x_max - x_count // first 
  else if (x_count  <= product.x_min) {
    quantity = product.x_max - product.x_min  //virtual count
    if (quantity == 0) quantity = 1
    //quantity += product.quantity -1
  }*/
  if (quantity<=0) return 0
  if (product.x_sale_quantity && product.x_sale_quantity > 0) {
    const verp = parseInt(product.x_sales_uom.toUpperCase().replace('VERP',''))/product.x_sale_quantity
    //console.log('VERP', verp)
    quantity = Math.round(quantity/verp)
    //console.log('result', quantity)
  }
  return quantity
}
export function getOrderCount(product:any){
  if (!product || product.x_calc || !product.x_sales_uom) return product.x_order_count || 0
  const quantity =parseInt(product.x_sales_uom.toUpperCase().replace('VERP','') || '1') / (product.x_quantity || 1)
  
  return product.x_order_count * quantity
}
export const nextPackage = computed(()=>{
  if (!selectedProduct.value) return null
  const quantity =parseInt(selectedProduct.value.x_sales_unit.toUpperCase().replace('VERP','') || '1')
  const arr = LinkedProducts.value.filter((p:any)=>p.quantity > quantity).sort(compare)
  if (!arr[0]) return null
  selectedProduct.value.x_max = arr[0].quantity-1
  return arr[0]
})

export function getUomQty(uom:String){
  try {
    return parseInt(uom.toUpperCase().replace('VERP','')) || 1
  }catch(e){
    return 1
  }
  
}

export function getSalesUnit(product:any,simpel=false){
  if (product.product_uom_id) product.x_sales_unit = product.product_uom_id[1]
  if (product.base_unit_name) product.x_sales_unit = product.base_unit_name
  
  if (product.x_product_type == 'service' || product.detailed_type == 'service' || product.x_type == 'service' ) return product.x_sales_unit == 'Dagen' ? 'Dag' : 'Uur'
  if ((!product.x_sales_unit || product.x_sales_unit.toLowerCase()=='verp1' || product.x_sales_unit=='Stuks') && simpel) return ''
  //if (!product.x_sales_unit || product.x_sales_unit.toLowerCase()=='verp1' || product.x_sales_unit=='Units') return 'stuk'
  //if (product.x_sales_unit.includes('VERP')) return 'DS (' + product.x_sales_unit.replace('VERP','') + ' st)'
  if (product.x_sales_unit && !product.x_sales_unit.toLowerCase().includes('verp')) return product.x_sales_unit
 // if (product.x_active_stock) return getUnits('VERP' + product.x_sale_quantity)
  if (!product.x_sales_uom)product.x_sales_uom = product.x_sales_unit
  return getUnits(product.x_sales_uom)
}

export function getUnits(unit:String){
  if (!unit || unit.toLowerCase()=='verp1' || unit=='Units' || unit == 'Stuks') return 'Stuk'
  if (unit.includes('VERP')) return 'DS (' + getUomQty(unit) + ' st)'
  return unit 
}

export function getAssortment(product:any){
  if (!product.x_assortment) return 
  //listAssort()
  if (product.assortment_id) product.x_assortment = product.assortment_id
  if (!assortList.value) assortList.value = config.assort
  const item = assortList.value.filter((a:any)=>a.id==product.x_assortment || a.id==product.x_assortment[0])[0]
  if (item) return item.display_name
  return product.x_assortment ? product.x_assortment[1] : product.x_assortment
}

function compare( a:any, b:any ) {
  const aItem = parseInt(a.x_sales_unit.toUpperCase().replace('VERP',''))
  const bItem = parseInt(b.x_sales_unit.toUpperCase().replace('VERP',''))
  return bItem - aItem
}
